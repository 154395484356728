import Analytics from './analytics.js';

export const CONSTs = {
  TYPE_ANALYTICS_CUSTOM_EVENT: 'triggerAnalytics'
};

export const handleAnalyticsInnerTextClick = (linkElement, type) => {
  linkElement &&
    linkElement.addEventListener('click', (event) => {
      const message = event.currentTarget && event.currentTarget.innerText;
      const siteId = getUrl(event).siteId;
      const href = getUrl(event).href;

      try {
        const analytics = new Analytics();
        analytics.ping(message, href, siteId, type);
      } catch (e) {}
    });
}; // close setAnalyticsClickEvent()

export const handleAnalyticsDataAttrClick = (linkElement, type) => {
  linkElement &&
    linkElement.addEventListener('click', (event) => {
      const message = event.currentTarget && event.currentTarget.getAttribute('data-analytics');
      const siteId = getUrl(event).siteId;
      const href = getUrl(event).href;
      try {
        const analytics = new Analytics();
        analytics.ping(message, href, siteId, type);
      } catch (e) {}
    });
}; // close setAnalyticsClickEvent()

const getUrl = (event) => {
  const href = event.currentTarget && event.currentTarget.getAttribute('href');
  let siteId = '';
  if (href.indexOf('&siteid') > -1) {
    siteId = parseQuery(href).siteid.split('#')[0];
  }
  return {
    href: href,
    siteId: siteId
  };
};

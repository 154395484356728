function skipToInitialize (linkQuerySelector, targetElement, focusElement) {
  var linkSelector = document.querySelector(linkQuerySelector)

  if (targetElement === null && linkSelector !== null) {
    linkSelector.classList.add('u-hidden')
  }

  if (linkSelector !== null) {
    linkSelector.addEventListener(
      'click',
      function (e) {
        e.preventDefault()

        try {
          e.target.blur()

          targetElement && targetElement.scrollIntoView()
          focusElement && focusElement.focus()
        } catch (e) {}
      }
    )
  }
}

var main = document.querySelector('main')
var mainLink = document.querySelector('main a')

var joinLink = document.querySelector('a[href*="pages02.net"]')
var joinSection = joinLink ? joinLink.closest('section') : joinLink

var footer = document.querySelector('footer')
var footerLink = document.querySelector('footer a[href*="sjobs.brassring.com"]')

skipToInitialize('#skipToMainContent', main, mainLink)
skipToInitialize('#skipToJoinOurTalentNetwork', joinSection, joinLink)
skipToInitialize('#skipToSearchAndApply', footer, footerLink)

function attachAnalyticsAttributes (page) {
    if (page == '/careers/experienced-financial-advisors' || page == '/careers/experienced-financial-advisors/') {
        // Grow by Acquisition link
        var growByAcquisition = document.querySelector('section[data-analytics-content-document-name="promo-EAR"] .promo-content a[href$="/careers/experienced-financial-advisors/practice-acquisition/grow-by-acquisition"]');

        if (growByAcquisition) {
            growByAcquisition.setAttribute('data-analytics', 'ear-practice-acquisition-links');
        }
        
        // Planning Your Succession link
        var planningYourSuccession = document.querySelector('section[data-analytics-content-document-name="promo-EAR"] .promo-content a[href$="/careers/experienced-financial-advisors/practice-acquisition/succession-planning"]');

        if (planningYourSuccession) {
            planningYourSuccession.setAttribute('data-analytics', 'ear-practice-acquisition-links');
        }

        // Selling Your Practice link
        var sellingYourPractice = document.querySelector('section[data-analytics-content-document-name="promo-EAR"] .promo-content a[href$="/careers/experienced-financial-advisors/practice-acquisition/selling-your-practice"]');

        if (sellingYourPractice) {
            sellingYourPractice.setAttribute('data-analytics', 'ear-practice-acquisition-links');
        }
    }

    if (page == '/careers/experienced-financial-advisors/culture-strength' || page == '/careers/experienced-financial-advisors/culture-strength/') {

        // By The Numbers quick link
        var byTheNumbers = document.querySelector('section[data-analytics-content-document-name="Quick Links"] .Content a[href$="pdf"]');

        if (byTheNumbers) {
            byTheNumbers.setAttribute('data-analytics', 'ear-culture-strength-quick-links');
        }

        // Ameriprise Awards and Accolades quick link
        var awardsAndAccolades = document.querySelector('section[data-analytics-content-document-name="Quick Links"] .Content a[href$="/financial-planning/why-choose-ameriprise/leader-in-financial-planning/"]');
        
        if (awardsAndAccolades) {
            awardsAndAccolades.setAttribute('data-analytics', 'ear-culture-strength-quick-links');
        }

        // Recently Joined Advisors quick link
        var recentlyJoined = document.querySelector('section[data-analytics-content-document-name="Quick Links"] .Content a[href$="/careers/experienced-financial-advisors/take-the-next-step/recently-joined"]');

        if (recentlyJoined) {
            recentlyJoined.setAttribute('data-analytics', 'ear-culture-strength-quick-links');
        }
    }

    if (page === '/careers/experienced-financial-advisors/take-the-next-step') {
        const bannerLink = document.querySelector('section[data-analytics-content-document-name="Take the Next Steps Promo"] a')
        if(bannerLink) {
            bannerLink.setAttribute('data-analytics','phone-link')
        }
    }

    if (page == '/careers/corporate/employee-benefits' || page == '/careers/corporate/employee-benefits/') {
        var advisorCompensation = document.querySelector('section.Content a[href$="/careers/new-financial-advisors/financial-advisor-opportunities/financial-advisor/"]');

        if (advisorCompensation) {
            advisorCompensation.setAttribute('data-analytics', 'advisor-compensation-link');
        }

        // Ameriprise Facts quick link
        var quickFacts = document.querySelector('section.Content a[href$="244058_w_0821-ameriprise-story_final.pdf"]');

        if (quickFacts) {
            quickFacts.setAttribute('data-analytics', 'careers-ameriprise-facts-quick-link');
        }
    }

    if (page === '/careers/india-jobs') {
        const searchAndApplyButton = document.querySelector('section[data-analytics-content-document-name="India section front hero"] .Button');
        if(searchAndApplyButton) {
            searchAndApplyButton.setAttribute('data-analytics', 'careers-search-and-apply-button');
        }
    }

    if (page === '/careers/experienced-financial-advisors/opportunity-value') {
        var womenAdvisor = document.querySelector('section.Content a[href$="/careers/experienced-financial-advisors/opportunity-value/women-advisors"]');

        if (womenAdvisor) {
            womenAdvisor.setAttribute('data-analytics', 'women-advisors-button');
        }

        var blackAdvisor = document.querySelector('section.Content a[href$="/careers/experienced-financial-advisors/opportunity-value/black-advisors"]');

        if (blackAdvisor) {
            blackAdvisor.setAttribute('data-analytics', 'black-advisors-button');
        }

        var christianAdvisors = document.querySelector('section.Content a[href$="/careers/experienced-financial-advisors/opportunity-value/christian-advisors"]');

        if (christianAdvisors) {
            christianAdvisors.setAttribute('data-analytics', 'christian-advisors-button');
        }
    }
    
    if(page === '/careers/experienced-financial-advisors/opportunity-value/women-advisors'){
        console.log(page);
        var brandAdvisor = document.querySelector('section.Content a[href$="/careers/experienced-financial-advisors/opportunity-value/aag"]');

        if (brandAdvisor) {
            brandAdvisor.setAttribute('data-analytics', 'branch-advisor-link');
        }

        var independentAdvisor = document.querySelector('section.Content a[href$="/careers/experienced-financial-advisors/opportunity-value/aia"]');

        if (independentAdvisor) {
            independentAdvisor.setAttribute('data-analytics', 'independent-advisor-link');
        }

        var financialAdvisors = document.querySelector('section.Content a[href$="/careers/experienced-financial-advisors/opportunity-value/afig"]');

        if (financialAdvisors) {
            financialAdvisors.setAttribute('data-analytics', 'financial-institution-advisor-link');
        }
        var financialAdvisorNew = document.querySelector('section.Content a[href$="/careers/redesign/experienced-financial-advisors/opportunity-value/afig"]');
        if (financialAdvisorNew) {
            financialAdvisorNew.setAttribute('data-analytics', 'financial-institution-advisor-link');
        }
    }
}
attachAnalyticsAttributes(window.location.pathname);
import { handleAnalyticsInnerTextClick, handleAnalyticsDataAttrClick } from '../../SiteNav/util.js'

import './disclaimer.scss'

/*
 * Careers Site Footer Disclaimer
 */
class CareersFooterDisclaimer extends HTMLElement {
  connectedCallback () {
    this.shadow = this.attachShadow({ mode: 'open' })

    this.template = this.querySelector('#disclaimer-template')

    this.template && this.template.content && this.shadow.appendChild(this.template.content)

     /* Footer disclosures and Social Network links Analytics */
     const footerDisclaimerContent = this.shadow.querySelector('.careers-footerSocialAndDisclaimer')
     const disclaimerLinks = footerDisclaimerContent && footerDisclaimerContent.getElementsByTagName('a')
     disclaimerLinks && disclaimerLinks.length > 0 && disclaimerLinks.forEach((link) => {
      let type = 'footer'
      handleAnalyticsDataAttrClick(link, type)
     })

     const footerDisclaimerCopy = document.querySelector('.footerDisclaimer')
     const disclaimerCopyLinks = footerDisclaimerCopy && footerDisclaimerCopy.getElementsByTagName('a')
     disclaimerCopyLinks && disclaimerCopyLinks.length > 0 && disclaimerCopyLinks.forEach((link) => {
      let type = 'footer'
      handleAnalyticsInnerTextClick(link, type)
    })
  } // close connectedCallback()
  
}

window.customElements.define('careers-footer-disclaimer', CareersFooterDisclaimer)
